export enum Role {
  SYSTEM = "system",
  USER = "user",
  ASSISTANT = "assistant",
  LOADER = "loader",
}

export enum Step {
  SUGGESTIONS,
  ASK_DATA,
  GET_DATA,
  NEXT_ACTION,
}

export interface ChatMessage {
  content?: string;
  role: Role;
}

export interface AIProps {
  componentId: string;
  businessId: string;
}

export interface ChatCompletion {
    id: string;
    object: string;
    created: number;
    model: string;
    choices: Choice[];
    usage: Usage;
    system_fingerprint: null | string; // assuming it can be null or a string based on the context
  }
  
  interface Choice {
    index: number;
    message: Message;
    logprobs: null; // assuming logprobs is always null based on the given example, adjust if it can be other types
    finish_reason: string;
  }
  
  interface Message {
    role: string;
    content: string;
  }
  
  interface Usage {
    prompt_tokens: number;
    completion_tokens: number;
    total_tokens: number;
  }