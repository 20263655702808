import "./App.css";
import "@chatui/core/dist/index.css";
import ChatArea from "./ChatArea";
import Header from "./Header";

const App = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
      }}
      className="App"
    >
      <Header />
      <ChatArea />
    </div>
  );
};

export default App;
