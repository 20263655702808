import React from "react";
import img from "./assets/mindhackerlogo.webp";

const Header: React.FC = () => {
  return (
    <header
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
        width: "100%",
      }}
    >
      <a href="https://www.mindhacker.lt/" target="blank">
        <img
          style={{ height: "60px", padding: "8px", cursor: "pointer" }}
          src={img}
          alt="404"
        />
      </a>
    </header>
  );
};

export default Header;
