import axios, { type AxiosResponse } from "axios";
import { type ChatMessage, Role, ChatCompletion } from "./interfaces";
import { ToolInstruction } from "./assistants";

const loadCompletion = async (messages: ChatMessage[]) => {
  console.log("LOAD COMPLETION: ", JSON.stringify(messages));

  const response: AxiosResponse<ChatCompletion> = await axios.post(
    "https://api.openai.com/v1/chat/completions",
    {
      messages,
      model: "gpt-4o-mini",
      temperature: 0,
    },
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      },
    }
  );

  try {
    console.log("GOT COMPLETION :", response);
    return response.data.choices[0].message.content as string;
  } catch (e) {
    console.log("LOAD COMPLETION FAILED: ", response);
    return "";
  }
};

export const sendMsg = (
  userMessage: string,
  oldMessageContext: ChatMessage[],
  assistant: ToolInstruction
) =>
  loadCompletion([
    {
      role: Role.SYSTEM,
      content: assistant.instructions,
    },
    ...oldMessageContext,
    {
      role: Role.USER,
      content: userMessage,
    },
  ]) as Promise<string>;
