import Chat, { Bubble, useMessages } from "@chatui/core";
import { useState } from "react";
import "./App.css";
import { sendMsg } from "./api";
import { ToolInstruction, courses, Course, keys, Key } from "./assistants";
import { Role } from "./interfaces";

const ChatArea = ({ setCurrent }: any) => {
  const { messages, appendMsg, setTyping, resetList } = useMessages([]);
  const [course, setCourse] = useState<Course | null>(courses[0]);
  const [assistant, setAssistant] = useState<ToolInstruction | null>(null);
  const [password, setPassword] = useState("");

  async function handleSend(type: any, val: string) {
    if (!assistant) return;
    if (type === "text" && val.trim()) {
      appendMsg({
        type: "text",
        content: { text: val },
        position: "right",
      });

      const context = messages.map((msg) => {
        return {
          role: msg.position === "left" ? Role.ASSISTANT : Role.USER,
          content: msg.content.text,
        };
      });

      setTyping(true);
      const responseText = await sendMsg(val, context, assistant);
      appendMsg({
        type: "text",
        content: { text: responseText.trim() },
      });
    }
  }

  function renderMessageContent(msg: any) {
    const { content } = msg;
    return <Bubble content={content.text} />;
  }

  const handleSelectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (!course || !assistant) return;
    const selectedTool = course.assistants!.find(
      (assistant) => assistant.id === event.target.value
    ) as ToolInstruction;
    resetList();
    setAssistant(selectedTool);
  };

  const handleKeySubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const currentKey: Key | undefined = keys.find(
      (_key) => _key.pw === password
    );
    if (!currentKey) {
      alert("Neteisingas raktas");
      return;
    } else {
      const currentCourse = courses.find(
        (course) => course.courseId === currentKey.id
      );
      setCourse(currentCourse || null);
      setAssistant(currentCourse?.assistants![0] || null);
      console.log(password, currentKey, currentCourse);
    }
    setPassword("");
    event.preventDefault();
  };

  return course && assistant ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "95%",
          height: "auto",
        }}
      >
        <select value={assistant.id} onChange={handleSelectionChange}>
          {course.assistants!.map((_assistant, index) => (
            <option key={index} value={_assistant.id}>
              {_assistant.title}
            </option>
          ))}
        </select>

        {assistant && (
          <div
            style={{ textAlign: "center", maxWidth: "100%", padding: "5px" }}
          >
            <h4 style={{ paddingBottom: "2px" }}>Apie ką šis modelis?</h4>
            <p>{assistant?.description}</p>
          </div>
        )}
      </div>
      <div
        style={{
          height: "75vh",
          width: "100%",
          padding: "10px",
          paddingBottom: "20px",
        }}
      >
        <Chat
          navbar={{ title: "Mindhacker Asistentas" }}
          messages={messages}
          renderMessageContent={renderMessageContent}
          onSend={handleSend}
          locale="en"
          placeholder="Type a message"
        />
      </div>
    </div>
  ) : (
    <form
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        maxWidth: "100%",
      }}
      onSubmit={handleKeySubmit}
    >
      <label style={{ padding: "6px" }}>
        <input
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Slaptažodis:"
          type="text"
          value={password}
          name="Key"
        />
      </label>
      <input type="submit" value="Įvesti" />
    </form>
  );
};

export default ChatArea;
